import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import bannerbg from '../../../assets/img/banner02.png';
import Footer from "../../../components/MSFooter";

const generalReport = () => {
    return (
        <React.Fragment>
            <Header parentMenu='admin-users' />
            <SiteBreadcrumb
                pageTitle="Reporte General"
                pageName="reporteGeneral"
                titleClass="page-title white-color"
                breadcrumbsImg={bannerbg}
            />
            <div>
                <iframe
                    width='100%'
                    height={window.screen.height}
                    src="https://lookerstudio.google.com/embed/reporting/b3900c36-69c1-411e-8887-bd4d705681e5/page/p_ev5o6t54bd" 
                    frameborder="0" 
                    allowfullscreen 
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                ></iframe>
            </div>          
            <Footer/>
        </React.Fragment>
    )
};

export default generalReport

