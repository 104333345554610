import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

const Metricas = ({ campaign }) => {
  const [metrics, setMetrics] = useState({
    totalRecipients: 0,
    clickedCount: 0,
    openRate: 0,
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        console.log(campaign)
        const response = await axios.post(`${process.env.REACT_APP_ROOT_URL}/campaigns/metrics`, { campaign });
        if (response.status === 200 || response.status === 201) {
          setMetrics(response.data.metrics)
        }
      } catch (error) {
        console.error('Error al obtener métricas:', error.message);
      }
    };

    fetchMetrics();
  }, [campaign]);

  return (
    <div className="metricas-container">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Métrica de {campaign.name}</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total de destinatarios</td>
            <td>{metrics.totalRecipients}</td>
          </tr>
          <tr>
            <td>Cantidad de clics</td>
            <td>{metrics.clickedCount}</td>
          </tr>
          <tr>
            <td>Tasa de apertura</td>
            <td>{metrics.openRate}%</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Metricas;