import React from 'react';
import { useEffect } from 'react';
import Header from '../../components/Layout/Header/Header';
import HomeMain from './HomeMain';
//import useUserLocalStorage from "../../components/UseUserLocalStorage/UseUserLocalStorage";
import Login from "../login";
import Footer from "../../components/MSFooter";
import axios from 'axios';

const reportUser = async (email) => {
    const date = new Date();
    await axios.post(`${process.env.REACT_APP_ROOT_URL}/reports/reportUser`,{
        type: 'session_start', 
        data: {
            date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, 
            time: `${date.getHours()}:${date.getMinutes()}`, 
            startTime: Date.now()
        },
        user: email}
    );
}

const HomePage = () => {
    const token = localStorage.getItem('userProfile'); 
    
    useEffect(() => {
        if (token) {
            const email = JSON.parse(token).token.email;
            reportUser(email);
        }
    }, []);
    
    if (!token) {
        console.error('user not authenticated');
        return <Login/>
    }

    return (
        <React.Fragment>
            <Header parentMenu='pages'/>
            <HomeMain/>
            <Footer/>
        </React.Fragment>
    );
}

export default HomePage;
