import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import MainNavBar from './MainNavBar';
import MobileNavBar from './MobileNavBar';
import useUserLocalStorage from '../../UseUserLocalStorage/UseUserLocalStorage';
import axios from 'axios';

import normalLogo from '../../../assets/img/logo/lite-logo.png';
import darkLogo from '../../../assets/img/logo/dark-logo.png';
import useUserStore from '../../../store/useUserStore';

const Header = ({parentMenu}) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const history = useHistory();
    //MODIFICAR
    const userId = useUserLocalStorage();

    const clearUserProfileAndRedirectToLogin = () => {
        // Clear the userProfile from localStorage
        localStorage.removeItem("userProfile");
        useUserStore.setState({isAuthenticated: false});
        history.push("/login");
    };

    const reportUser = async (email) => {
        const date = new Date();
        await axios.post(`${process.env.REACT_APP_ROOT_URL}/reports/reportUser`,{
            type: 'session_end', 
            data: {
                date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, 
                time: `${date.getHours()}:${date.getMinutes()}`, 
                endTime: Date.now()
            },
            user: email}
        );
    }

    const handleLogout = () => {
        // Clear userProfile from localStorage and redirect to /login
        const token = localStorage.getItem('userProfile');
        const email = JSON.parse(token).token.email;
        reportUser(email);
        clearUserProfileAndRedirectToLogin();
    };

    return (
        <React.Fragment>
            <div className={'full-width-header header-style1 home8-style4 main-home'}>
                <header id="rs-header" className='rs-header'>
                    <div className={'menu-area menu-sticky sticky'}>
                        <div className="container">
                            <div className="row y-middle">
                                <div id="container-logo" className="col-lg-2">
                                    <div className="logo-area hidden-md">
                                        <Link to="/">
                                            <img className="normal-logo"
                                                 src={normalLogo} alt=""/>
                                            <img className="sticky-logo"
                                                 src={darkLogo} alt=""/>
                                        </Link>
                                    </div>
                                </div>
                                <div id="container-nav-items" className="col-lg-8 text-end">
                                    <div className="rs-menu-area">
                                        <div className="main-menu">
                                            <div className="mobile-menu md-display-block">
                                                <Link to="/" className="mobile-normal-logo">
                                                    <img className="normal-logo" src={normalLogo} alt=""/>
                                                </Link>
                                                <Link to="/" className="mobile-sticky-logo">
                                                    <img src={darkLogo}  alt="logo"/>
                                                </Link>
                                                <Link to="#" className="rs-menu-toggle" onClick={() => {setMenuOpen(!menuOpen)}}>
                                                    <i className="fa fa-bars"></i>
                                                </Link>
                                            </div>
                                            <MainNavBar parentMenu={parentMenu}/>
                                        </div>
                                    </div>
                                </div>
                                <div id="container-account-icons" className="col-lg-2 relative text-end hidden-md">
                                    <div className="expand-btn-inner search-icon">
                                        <ul className="expand-items">
                                            <li className="user-icon last-icon hidden-lg">
                                                {/*{userId ? (*/}
                                                {/*    <Link to="#">*/}
                                                {/*        <i className="fa fa-user-o" aria-hidden="true"></i></Link>*/}
                                                {/*) : (*/}
                                                {/*    <Link to="/login">*/}
                                                {/*        <i className="fa fa-user-o" aria-hidden="true"></i></Link>*/}
                                                {/*)}*/}
                                            </li>
                                            {userId && (
                                                <li className="sidebarmenu-search">
                                                    <Link to="#" onClick={handleLogout} className="rs-search" href="#">
                                                        <i className="fa fa-sign-out"></i>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MobileNavBar
                        menuOpen={menuOpen}
                        parentMenu={parentMenu}
                    />
                    <div onClick={() => setMenuOpen(false)}
                         className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
                </header>
            </div>
        </React.Fragment>
    );
}

export default Header;