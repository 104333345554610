import {Link} from "react-router-dom";
import React from "react";

const Navbar = ({isLoggedIn, isAdmin, parentMenu}) => {
    if (!isLoggedIn) {
        // Si el usuario no está logueado, retorna un navbar vacío
        return null
    }

    return (
        <nav className="rs-menu hidden-md">
            <ul className="nav-menu">
                {/* Elementos comunes a todos los usuarios */}
                <li className={"rs-mega-menu menu-item"}/>
                <li className={"menu-item"}>
                    <Link to="/cursos"> Todos los Cursos </Link>
                </li>
                <li className={"menu-item"}>
                    <Link to="/mis-cursos"> Mis Cursos </Link>
                </li>

                {/* Elementos exclusivos para administradores */}
                {isAdmin && (
                    <>
                        <li className={"menu-item-has-children"}>
                            <Link to="#">Administración</Link>
                            <ul className="sub-menu">
                                <li><Link to="/admin/categories"> Categorías </Link></li>
                                <li><Link to="/admin/cursos"> Cursos </Link></li>
                                <li><Link to="/admin/request"> Solicitudes de Acceso </Link></li>
                                <li><Link to="/admin/usuarios"> Usuarios </Link></li>
                                <li><Link to="/admin/activationCodes"> Códigos de Activación </Link></li>
                                <li><Link to="/groups"> Grupos </Link></li>
                                <li><Link to="/admin/campañas"> Campañas </Link></li>
                                <li><Link to="/admin/email-loader"> Administrador de Grupos </Link></li>
                            </ul>
                        </li>
                        <li className={"menu-item-has-children"}>
                            <Link to="#">Reportes</Link>
                            <ul className="sub-menu">
                                <li><Link to="/report-users"> Métricas por Usuario </Link></li>
                                <li><Link to="/report-courses"> Métricas por Curso </Link></li>
                                <li><Link to="/report-general"> Métricas Generales </Link></li>
                            </ul>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default Navbar;
