import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import axios from "axios";
import useUserLocalStorage from "../../components/UseUserLocalStorage/UseUserLocalStorage";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from 'react-bootstrap';

const CampaignsCreator = ({ initialData, isEditing, onCancel, onCampaignSave }) => {
  const [recipientEmails, setRecipientEmails] = useState(initialData?.recipientEmails || []);
  const [subject, setSubject] = useState(initialData?.subject || '');
  const [body, setBody] = useState(initialData?.body || '');
  const [users, setUsers] = useState([]);
  const [campaignName, setCampaignName] = useState(initialData?.name || '');

  const modulo = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };

  useEffect(() => {
    if (initialData?.recipients && isEditing) {
      setRecipientEmails(initialData.recipients);
    }
  }, [initialData, isEditing]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
          const userUrl = `${process.env.REACT_APP_ROOT_URL}/user/getAllUserEmails`;
          const response = await axios.get(userUrl);

          if (!response.status === 201) {
            throw new Error('la request fallo con el status: ' + response.status);
          }

          const data = response.data.users;
          setUsers(data);
      } catch (error) {
        console.error('Error al obtener usuarios:', error.message);
      }
    };

    fetchUsers();
  }, []);

  const handleUserSelection = (selectedOption) => {
    console.log(selectedOption)
    setRecipientEmails(selectedOption.map((option => option.value)));
  };

  const handleFormSubmit = async (event, isSend) => {
    event.preventDefault();

    const rootUrl = process.env.REACT_APP_ROOT_URL;

    try {
      const url = isSend ? `${process.env.REACT_APP_ROOT_URL}/emails/send` : `${process.env.REACT_APP_ROOT_URL}/campaigns/save`;
      const response = await axios.post(url, {
        campaignName,
        recipientEmails,
        subject,
        body,
        rootUrl,
      });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          position: "center",
          text: isSend ? `Email Enviado Correctamente!` : `Campaña Guardada Correctamente!`,
          showConfirmButton: true,
          confirmButtonText: `OK`,
          customClass: {
            confirmButton: 'custom-button-confirmed',
          },
          timer: 2000,
        });

        if (onCampaignSave) {
          onCampaignSave();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Ups, algo salio mal. Por favor intenta de nuevo más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
          confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
      console.error('Error:', error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      const campaign = {
        id: initialData.id,
        campaignName: campaignName,
        recipientEmails: recipientEmails,
        subject: subject,
        body: body,
      }

      const url = `${process.env.REACT_APP_ROOT_URL}/campaigns/update`;
      const response = await axios.post(url, campaign);

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          position: "center",
          text: 'Campaña actualizada exitosamente',
          showConfirmButton: true,
          confirmButtonText: `OK`,
          customClass: {
            confirmButton: 'custom-button-confirmed',
          },
          timer: 2000,
        });

        if (onCampaignSave) {
          onCampaignSave();
        }

      } else {
        throw new Error('Error actualizando la campaña: ' + response.statusText);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        position: "center",
        text: `Ups, algo salio mal. Por favor intenta de nuevo más tarde.`,
        showConfirmButton: true,
        confirmButtonText: `OK`,
        customClass: {
          confirmButton: 'custom-button-confirmed',
        },
        timer: 4000,
      });
      console.error('Error:', error.message);
    }
  };

  const handleBodyChange = (value) => {
    setBody(value);
  };

  return (
    <React.Fragment>
      <ScrollToTop scrollClassName="scrollup orange-color" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 my-3">
            <form onSubmit={(e) => handleFormSubmit(e, true)}>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Nombre de la Campaña"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <Select
                  isMulti
                  name="recipientEmails"
                  options={users.map((user) => ({ value: user.email, label: user.groupName }))}
                  id="recipientEmails"
                  //value={initialData?.recipients}
                  onChange={handleUserSelection}
                  placeholder="Destinatarios"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  placeholder="Asunto"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <ReactQuill
                  id='body'
                  theme="snow"
                  modules={modulo}
                  value={body}
                  onChange={handleBodyChange}
                  placeholder="Mensaje"
                  required
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={onCancel} className="me-2">
                  Cancelar
                </Button>
                { isEditing ? 
                  <Button type="button"  className="me-2" onClick={() => handleUpdate()}>
                    Editar
                  </Button>
                  : 
                  <Button type="button"  className="me-2" onClick={(e) => handleFormSubmit(e, false)}>
                    Guardar
                  </Button>
                }
                <Button type="submit">
                  Enviar Correo
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignsCreator;